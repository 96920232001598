import { makeStyles, Theme } from '@material-ui/core';
import FadeInOut from '@common/animations/FadeInOut';
import StaggerParent from '@common/animations/StaggerParent';
import FadeInUp from '@common/animations/FadeInUp';
import { makeEmailLowercaseAndTrim } from '@common/utils/makeEmailLowercaseAndTrim';
import { useTranslation } from 'next-i18next';
import {
  Body,
  Button,
  Header,
  Input,
  Password,
} from '@jobdone/jobdone-web-design-system';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import { SigninActions } from '@modules/Login/common/consts';
import { joiResolver } from '@hookform/resolvers/joi';
import { signInSchema } from '../../schemas';
import useLogin from './useUILogin';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    textAlign: 'center',
    userSelect: 'none',
    maxWidth: 360,
    [theme.breakpoints.down('xs')]: {
      padding: 20,
      textAlign: 'left',
    },
  },
  title: {
    marginTop: 20,
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
      marginBottom: 0,
    },
  },
  body: {
    width: '100%',
    color: theme.palette.grey[700],
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  emailInput: {
    marginTop: 32,
  },
  inputContainerClassname: {
    marginBottom: 32,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 24,
    },
  },
  input: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  btn: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  forgetPassBtn: {
    marginTop: 12,
    marginBottom: 12,
  },
}));

const Login = ({ actions }: SigninActions) => {
  const styles = useStyles();
  const { t } = useTranslation(['common', 'login', 'validations']);
  const router = useRouter();
  const {
    control, handleSubmit, formState: { errors }, watch, setValue, getValues,
  } = useForm({
    mode: 'onSubmit',
    resolver: joiResolver(signInSchema),
  });
  const {
    models: { loading, error },
    operations: { onSubmit, deleteServerError },
  } = useLogin({ actions, getValues });

  return (
    <FadeInOut>
      <StaggerParent className={styles.root}>
        <FadeInUp>
          <Header
            variant='h1'
            aria-label='login-header'
            className={styles.title}
          >
            {t('login:portal_login_header')}
          </Header>
        </FadeInUp>
        <FadeInUp>
          <Body variant='large' aria-label='login-body' className={styles.body}>
            {t('login:enter_credentials')}
          </Body>
        </FadeInUp>
        <FadeInUp>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='username'
              defaultValue=''
              control={control}
              render={({ field }) => (
                <Input
                  id='login-email-input'
                  disabled={loading}
                  autoFocus
                  className={styles.input}
                  value={field.value}
                  onChange={(e) => {
                    deleteServerError();
                    setValue('username', e.target.value.replace(' ', ''), { shouldValidate: true });
                  }}
                  onBlur={() => {
                    setValue('username', makeEmailLowercaseAndTrim(watch('username')), { shouldValidate: true });
                  }}
                  containerClassName={cx(styles.inputContainerClassname, styles.emailInput)}
                  aria-label='login-email-input'
                  error={!!errors.username || !!error}
                  locale={{
                    label: t('common:email'),
                    placeholder: 'name@example.com',
                    errorMessage: t(errors?.username?.message) || error,
                  }}
                />
              )}
            />
            <Controller
              name='password'
              defaultValue=''
              control={control}
              render={({ field }) => (
                <Password
                  id='login-password-input'
                  disabled={loading}
                  className={styles.input}
                  value={field.value}
                  onChange={(e) => {
                    deleteServerError();
                    field.onChange(e);
                  }}
                  containerClassName={styles.inputContainerClassname}
                  aria-label='login-password-input'
                  error={!!errors.password || !!error}
                  locale={{
                    label: t('common:password'),
                    errorMessage: t(errors?.password?.message) || error,
                  }}
                />
              )}
            />
            <Button
              id='login-submitBtn'
              aria-label='login-submit-btn'
              className={styles.btn}
              label={t('common:login')}
              size='large'
              type='submit'
              disabled={loading || !watch('password') || !watch('username')}
              loading={loading}
            />
            <Button
              aria-label='login-forgot-pass-btn'
              id='login-forgot-pass'
              className={cx(styles.btn, styles.forgetPassBtn)}
              label={t('login:forgot_pass')}
              size='large'
              color='ghost'
              disabled={loading}
              onClick={() => router.push('/reset-password')}
            />
          </form>
        </FadeInUp>
      </StaggerParent>
    </FadeInOut>
  );
};

export default Login;
