import FadeInUp from '@common/animations/FadeInUp';
import { Tag } from '@jobdone/jobdone-web-design-system';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  tag: {
    position: 'absolute',
    top: 16,
    left: 'calc(50% - 28.44px)',
  },
}));

const useDedectOfflineMode = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { isOnline };
};

// If we have any props on used component, we need to define and export then write like this:
// function withDedectOfflineMode<T>(WrappedComponent: ComponentType<T>) {

function withDedectOfflineMode(WrappedComponent: React.FC) {
  return () => {
    const classes = useStyles();
    const { t } = useTranslation('common');
    const { isOnline } = useDedectOfflineMode();

    return (
      <>
        {!isOnline && (
          <FadeInUp>
            <Tag
              className={classes.tag}
              backgroundColor='#F99C10'
              color='#FFF'
            >
              {t('common:offline')}
            </Tag>
          </FadeInUp>
        )}
        <WrappedComponent />
      </>
    );
  };
}

export default withDedectOfflineMode;
