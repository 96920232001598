import React from 'react';
import { EmailLayout } from '@components/EmailLayout/EmailLayout';
import { useLogin } from './models';
import { Login } from './components';

const LoginContainer = () => {
  const { operations: { onLogin } } = useLogin();

  return (
    <EmailLayout mode='signIn'>
      <Login actions={{ onLogin }} />
    </EmailLayout>
  );
};

export default LoginContainer;
